exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forelasningar-index-js": () => import("./../../../src/pages/forelasningar/index.js" /* webpackChunkName: "component---src-pages-forelasningar-index-js" */),
  "component---src-pages-kurser-index-js": () => import("./../../../src/pages/kurser/index.js" /* webpackChunkName: "component---src-pages-kurser-index-js" */),
  "component---src-pages-moteskostnadskalkylatorn-js": () => import("./../../../src/pages/moteskostnadskalkylatorn.js" /* webpackChunkName: "component---src-pages-moteskostnadskalkylatorn-js" */),
  "component---src-pages-personalomsattningskalkylatorn-js": () => import("./../../../src/pages/personalomsattningskalkylatorn.js" /* webpackChunkName: "component---src-pages-personalomsattningskalkylatorn-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-courses-js": () => import("./../../../src/templates/courses.js" /* webpackChunkName: "component---src-templates-courses-js" */),
  "component---src-templates-customer-content-js": () => import("./../../../src/templates/customer-content.js" /* webpackChunkName: "component---src-templates-customer-content-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-leadership-program-js": () => import("./../../../src/templates/leadership-program.js" /* webpackChunkName: "component---src-templates-leadership-program-js" */),
  "component---src-templates-lms-js": () => import("./../../../src/templates/lms.js" /* webpackChunkName: "component---src-templates-lms-js" */),
  "component---src-templates-plain-page-js": () => import("./../../../src/templates/plain-page.js" /* webpackChunkName: "component---src-templates-plain-page-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-talks-js": () => import("./../../../src/templates/talks.js" /* webpackChunkName: "component---src-templates-talks-js" */)
}

